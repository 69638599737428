import React from 'react';

const HowMakeOrder = () => {
    return (
        <>
            <h2>Как сделать заказ</h2>
            <div>
                <h3>1. Войти или зарегистрироваться</h3>
                <p>Оформить заказ можно только в личном кабинете. Войдите или зарегистрируйтесь</p>
            </div>
            <div>
                <h3>2. Добавить товар в «Корзину»</h3>
                <p>В карточке товара выберите подходящий размер и нажмите «Добавить в корзину»</p>
            </div>
            <div>
                <h3>3. Перейти в корзину</h3>
                <p>Для продолжения оформления заказа перейдите в «Корзину»</p>
            </div>
            <div>
                <h3>4. Проверить заказ</h3>
                <p>Проверьте состав заказа. Измените количество, отложите или удалите товар, если ошиблись с выбором</p>
            </div>
            <div>
                <h3>7. Завершить оформление заказа</h3>
                <p>Нажмите кнопку «Оплатить заказ». После поступления оплаты заказ будет оформлен</p>
            </div>
        </>
    );
};

export default HowMakeOrder;
