import React from 'react';

const AdSliderTwo = () => {
    return (
        <div className={'ad-slider-item ad-slider-two'}>
            <h2>Slider two</h2>
        </div>
    );
};

export default AdSliderTwo;