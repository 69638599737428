import React from 'react';

const PaymentMethods = () => {
    return (
        <>
            <h2>Способы оплаты</h2>
        </>
    );
};

export default PaymentMethods;
