import React from 'react';

const AboutUs = () => {
    return (
        <>
            <h2>PERN SHOP</h2>
            <div>
                <h3>Mega Marketplace - low price</h3>
            </div>
        </>
    );
};

export default AboutUs;
