import React from 'react';

const PurchaseReturns = () => {
    return (
        <>
            <h2>Возврат товара надлежащего качества</h2>
            <div>
                <h3>Условия возврата</h3>
                <p>Товар принимается к возврату только в полной комплектации, со всеми упаковками и наклейками, в неношеном/неиспользованном виде</p>
            </div>
            <div>
                <h3>Срок возврата</h3>
                <p>Срок возврата товара надлежащего качества составляет 14 дней с момента получения товара, если иное не было оговорено при продаже товара</p>
            </div>
        </>
    );
};

export default PurchaseReturns;
