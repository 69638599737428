import React from 'react';

const Delivery = () => {
    return (
        <>
            <h2>Доставка</h2>
            <div>
                <h3>Доставки нет</h3>
            </div>
        </>
    );
};

export default Delivery;
